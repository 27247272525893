import React, { Component } from "react";
import { connect } from "react-redux";
import { LoginStates, Spinner } from "smart-ui-library";

import "./Impersonate.css";

import { AuthService } from "smart-component-library";

export class Impersonate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isBusy: false,
      brandLogo: null,
      impersonateUsernameErrorMessage: null,
      impersonateUserIdErrorMessage: null,
      impersonateReasonErrorMessage: null,
      loginState: LoginStates.loginStates.Impersonate,
    };

    this.onImpersonateClick = this.onImpersonateClick.bind(this);
    this.onUserInteraction = this.onUserInteraction.bind(this);
  }

  showPendingSpinnerTimeoutID;

  onImpersonateClick(impersonateUsername, impersonateUserId, reason) {
    //First we do some checks before impersonating...
    if (!impersonateUsername) {
      this.setState({
        impersonateUsernameErrorMessage: "Please provide the client username",
      });
    }
    if (!impersonateUserId) {
      this.setState({
        impersonateUserIdErrorMessage: "Please provide the client user ID",
      });
    }
    if (!reason) {
      this.setState({
        impersonateReasonErrorMessage: "Please provide the reason why you are impersonating",
      });
    }

    if (!impersonateUsername || !impersonateUserId || !reason) {
      return;
    }

    //We have all we need to continue...

    let notStillBusy = this.setIsBusy();
    if (notStillBusy) {
      this.setPendingStateSoon();
      AuthService.impersonate(impersonateUsername, impersonateUserId, reason)
        .then(() => {
          console.log("impersonation successful, navigating...");
          let url = `/`;
          if (window.location.search) {
            //We do not check any routes here, or do any logic around routing,
            //The AuthenticateRoute component/route is responsible for doing that,
            //so we just navigate to that route with any redirectUrl included (search).
            url += window.location.search;
          }
          console.log(`navigating to ${url}`);
          window.location = url;
        })
        .catch((data) => {
          //any error should already be passed to the component via redux.
          console.log("impersonation not successful... getting error message");
          if (data.response?.status === 400 && data.message) {
            this.setState({
              loginState: LoginStates.loginStates.Impersonate,
              impersonateUsernameErrorMessage: data.message,
              impersonateUserIdErrorMessage: data.message,
              impersonateReasonErrorMessage: null,
            });
          } else {
            console.error(data);
            this.setState({
              loginState: LoginStates.loginStates.Impersonate,
              impersonateUsernameErrorMessage: null,
              impersonateUserIdErrorMessage: null,
              impersonateReasonErrorMessage: "Server error, please try again or contact support.",
            });
          }
        })
        .finally(() => {
          this.clearPendingState();
          this.setNotBusy();
        });
    } else {
      console.log("still busy!");
    }
  }

  setIsBusy() {
    if (!this.state.isBusy) {
      this.setState({
        isBusy: true,
      });
      return true;
    }
    return false;
  }

  setNotBusy() {
    this.setState({
      isBusy: false,
    });
  }

  onUserInteraction() {
    this.clearPendingState();
    this.setState({
      impersonateUsernameErrorMessage: null,
      impersonateUserIdErrorMessage: null,
      impersonateReasonErrorMessage: null,
    });
  }

  clearPendingState() {
    clearTimeout(this.showPendingSpinnerTimeoutID);
  }

  setPendingStateNow() {
    clearTimeout(this.showPendingSpinnerTimeoutID);
    this.setState({
      loginState: LoginStates.Pending,
    });
  }

  setPendingStateSoon() {
    clearTimeout(this.showPendingSpinnerTimeoutID);
    this.showPendingSpinnerTimeoutID = setTimeout(() => this.setPendingStateNow(), 2000);
  }

  render() {
    if (this.state.loginState) {
      return (
        <div id="impersonate" className="impersonate">
          <LoginStates
            description="Enter user details and the reason below. User IDs are located in the COSAIN Support folder on SharePoint."
            loginState={this.state.loginState}
            impersonateUsernameErrorMessage={this.state.impersonateUsernameErrorMessage}
            impersonateUserIdErrorMessage={this.state.impersonateUserIdErrorMessage}
            impersonateReasonErrorMessage={this.state.impersonateReasonErrorMessage}
            onImpersonateClick={this.onImpersonateClick}
            onUserInteraction={this.onUserInteraction}
          />
        </div>
      );
    } else {
      console.log("Render: login state is null, so showing spinner");
      return <Spinner />;
    }
  }
}

const stateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  token: state.auth.token,
});

const dispatchToProps = (dispatch) => {};

export default connect(stateToProps, dispatchToProps)(Impersonate);
